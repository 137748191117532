import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_COCKPIT_EXPLORER_SERVICE}/cockpit-explorer-service`);

const cockpitExplorerService = {
    getExplorerCounts(status, tags, search) {
        const params = {
            status: status !== undefined ? status : -1,
            tags: tags !== undefined ? tags : '',
            search: search !== undefined ? search : '',
        };

        return new Promise((resolve, reject) => {
            service.getRequest(1, 'explorers/counts',
                params, resolve, reject,
            );
        })
    },
    getBusinessStatusTypes(then, error) {
        service.getRequest(1, 'business-status/types', null, then, error);
    },
    getDevicePools(then, error) {
        service.getRequest(1, 'device-pools', null, then, error);
    },
    getDevicePoolsTable(params, then, error) {
        service.getRequest(1, 'device-pools/table', params, then, error);
    },
    createDevicePool(devicePool, then, error) {
        service.putRequest(1, 'device-pool', devicePool, then, error);
    },
    updateDevicePool(devicePool, then, error) {
        service.patchRequest(1, 'device-pool/{id}', devicePool, then, error, devicePool.id);
    },
    deleteDevicePool(id, then, error) {
        service.deleteRequest(1, 'device-pool/{id}', null, then, error, id);
    },
    getBusinessWorkflows(then, error) {
        service.getRequest(1, 'business-workflows', null, then, error);
    },
    assignToDevicePool(explorerIds, devicePoolId, then, error) {
        service.patchRequest(1, 'device-pool/{id}/assign-explorers', explorerIds, then, error, devicePoolId);
    },
    getBusinessStatusesByDevicePool(devicePoolId, oldDevicePoolId, then, error) {
        service.getRequest(1, 'device-pool/{id}/business-statuses', {
            'old-id': oldDevicePoolId,
        }, then, error, devicePoolId);
    },
    getExplorerTagsPromise() {
      return new Promise((resolve, reject) => {
        service.getRequest(1, 'tags', null, resolve, reject);
      })
    },
    checkTagValidity(tag) {
        return new Promise((resolve, reject) => {
            service.getRequest(1, 'check-tag-validity/{tag}',
                null, resolve, reject, tag,
            );
        })
    },
};

export default cockpitExplorerService;

<template>
  <div>
    <XDialog
      :value="value"
      title="Customize and Download your Explorer Appliance OS Image"
      @input="(v) => closeDialog(v)"
      width="1280"
    >
      <template #dialog-content>
        <div class="get-explorer-dialog">
          <div class="get-explorer-text">
            <div>
              To convert your Single Board Computer into a Degust Explorer network probe, you need to configure and download the
              most current version of the Degust® Explorer OS appliance image.
            </div>
            <div class="tag-paragraph">
              <div>
                Tags will be assigned to group the Explorers for testing and statistics. Tags may assign a location, a
                testing purpose, a tariff name, or anything else.
              </div>
              <div>
                They may look like this:
              </div>
              <div class="example-tags">
                <div v-for="(exampleTag, i) of exampleTags" :key="i" class="example-tag">{{ exampleTag }}</div>
              </div>
              <div>
                To enter a tag, just write the name and press the ENTER key or select a tag from the list of previously
                used tags, which pops up as soon you start typing.
              </div>
            </div>
            <div>
              Explorers will get a numeric hostname with a 3-character prefix of your choice.
            </div>
            <div>
              <a href="https://service.acctopus.com/portal/en/kb/articles/install-degust-explorer-on-sd-card" target="_blank">Read full instructions</a>
            </div>
          </div>
          <XForm v-model="valid" class="get-explorer-form">
            <ProjectSelect
              v-model="imageConfiguration.project"
              label="Initially Assign Systems running this Image to Project"
              hash
              required/>
            <XCheckbox v-model="imageConfiguration.create" label="Automatically Create and Run Tests in a new Project"/>
            <ExplorerTagSelect v-model="imageConfiguration.addTags"/>
            <XTextField v-model="imageConfiguration.hostPrefix" label="Hostname Prefix" max-length="10"/>
            <XSelect v-model="imageConfiguration.osType" label="Hardware brand" :items="hardwareBrands" required/>
            <v-divider/>
            <InputRow>
              <XBtn text="download" color="save" @click="download" :disabled="!valid"/>
              <XBtn text="cancel" color="secondary" icon="mdi-cancel" @click="$emit('input', false)"/>
            </InputRow>
          </XForm>
        </div>
      </template>
    </XDialog>

    <v-overlay style='z-index:300;' :absolute="true" :value="overlay">
      <div>
        <h1>
          <p class="text-center">
            We are customizing your Explorer OS image.
          </p>
          <br />
          <p class="text-center">Please be patient.</p>
          <br />
          <p class="text-center">
            You will get the download link in this browser tab.
          </p>
          <p class="text-center">
            Please do not use the back or reload function of your browser.
          </p>
          <div class="mt-12">
            <p class="text-center" style="font-size: 70px">
              {{ content }}
            </p>
          </div>
          <div style="text-align: center;" class="mt-12">
            <a target="_blank" href="https://service.acctopus.com/portal/en/kb/articles/install-degust-explorer-on-sd-card">Read full instructions
            </a>
          </div>
        </h1>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import XDialog from '@/components/basic/XDialog.vue';
import XBtn from '@/components/basic/XBtn.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XForm from '@/components/basic/XForm.vue';
import XCheckbox from '@/components/basic/XCheckbox.vue';
import ExplorerTagSelect from '@/components/specific/Explorer/ExplorerTagSelect.vue';
import XTextField from '@/components/basic/XTextField.vue';
import ProjectSelect from '@/components/specific/ProjectSelect.vue';
import XSelect from '@/components/basic/XSelect.vue';
import imageCreatorService from "@/js/services/ImageCreatorService";

export default defineComponent({
  name: "GetExplorerDialog",

  components: {
    XSelect,
    ProjectSelect,
    XTextField,
    ExplorerTagSelect,
    XCheckbox,
    XForm,
    InputRow,
    XBtn,
    XDialog,
  },

  props: {
    value: Boolean,
  },

  setup() {
    const { getCloseMethod } = inject("DialogsRoot");
    const closeAsync = getCloseMethod("GetExplorerDialog");

    return {
      closeAsync,
    };
  },

  data() {
    return {
      imageConfiguration: {
        project: '',
        create: true,
        addTags: ['allExplorers'],
        hostPrefix: 'NEW',
        osType: 'raspberry-pi-4',
      },
      valid: true,
      hardwareBrands: [
        {
          value: 'raspberry-pi-4',
          text: 'Raspberry Pi 4',
        },
      ],
      exampleTags: [
        'location=cologne',
        'department=QA',
        'tariff=fibre500',
        'iperf',
      ],
      //loading: false,
      content: "",
      totalTime: 120,
      overlay: false,
    };
  },
  methods: {
    closeDialog(v) {
      if (this.closeAsync) {
        this.closeAsync();
        return;
      }

      this.$emit("input", v)
    },

    download() {
      //this.loading = true;
      //this.showNotification('Image creation started. Please wait. This can take a few minutes.', 10000);
      this.countDown();
      imageCreatorService.create(this.imageConfiguration, (response) => {
        window.location.href = response.link;
        this.$emit('input', false);
        //this.loading = false;
        this.overlay = false;
      });
    },
    countDown() {
      this.overlay = true;
      this.content = this.totalTime + "s";
      let clock = setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "Still processing.. few more seconds to wait";
          this.totalTime = 120;
        }
      }, 1000);
    },
  },
});
</script>

<style scoped>
.get-explorer-dialog {
  display: flex;
  gap: 20px;
}

.get-explorer-dialog > * {
  flex: 0 0 calc(50% - 10px);
}

.get-explorer-text {
  font-size: 14px;
  color: var(--v-gray-text-base);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tag-paragraph {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.example-tags {
  display: flex;
  gap: 5px;
  line-height: 1.375;
}

.example-tag {
  color: var(--v-text-on-gray-text-background-base);
  background-color: var(--v-gray-text-background-base);
}

.get-explorer-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>

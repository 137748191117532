<template>
  <div class="select-combobox">
    <v-combobox
      ref="select"
      v-bind="comboboxProps"
      @input="(v) => emitInput(v)"
      @click="$emit('click', $event)"
    >
      <template v-if="chips" #selection="selection">
        <v-chip 
          :class="{ 
            'select-combobox__chip': true,
            'select-combobox__chip--persistent': checkChipPersistent(selection.item),
          }"
          @click:close="() => unselectItem(selection.item)" 
          small 
          :close="!checkChipPersistent(selection.item)"
        >
          {{ itemText(selection.item) }}
        </v-chip>
      </template>
    </v-combobox>

    <HelpButton 
      class="select-combobox__help"
      :data-help="$attrs['data-help']"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import getRule from "@/js/rules";
import HelpButton from "@/components/basic/HelpButton.vue";
import { 
  getRequiredRule, 
  pxOrNot 
} from "@/js/general";

export default defineComponent({
  name: "SelectCombobox",

  components: {
    HelpButton
  },

  props: {
    value: {
      type: [String, Number, Object, Array],
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: Function,
      default: (item) => item["value"]
    },
    itemText: {
      type: Function,
      default: (item) => item["text"]
    },
    required: [Boolean, Function],
    rules: {
      type: Array,
      default: () => []
    },
    validateImmediately: Boolean,
    disabled: Boolean,
    color: String,
    autocomplete: {
      type: Boolean,
      default: true
    },
    id: String,
    inputType: String,
    outputType: String,
    multiple: [Boolean, Function],
    chips: [Boolean, Function],
    minHeight: [Number, String],
    dense: Boolean,
    noSpacesInValues: Boolean,
    width: [Number, String],
    keepValue: Boolean,
    noPreselect: Boolean,
    checkChipPersistent: {
      type: Function,
      default: () => false,
    },
    isObjectInReturn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dataValue: null,
      searchInput: ""
    };
  },

  computed: {
    comboboxProps() {
      return {
        chips: this.chips,
        class: `select-combobox__input select-combobox__input--${this.color}`,
        dense: !this.chips || this.dense,
        disabled: this.disabled,
        itemText: this.itemText,
        itemValue: this.itemValue,
        items: this.items,
        label: this.label,
        multiple: this.multiple,
        rules: this.rules,
        style: `min-height: ${this.minHeight}px; ${pxOrNot('width', this.width)}`,
        value: this.dataValue,
        menuProps: { offsetY: true },
        id: this.id,
        hideDetails: "auto",
        outlined: true,
        returnObject: this.isObjectInReturn
      };
    },

    computedRules() {
      const rules = [];
      
      if (this.required) {
        rules.push(getRequiredRule());
      }

      return [
        ...rules,
        ...this.rules.map(rule => 
          typeof rule === "string" ? getRule(rule) : rule
        )
      ];
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.reloadValue();
      }
    },
    
    items() {
      this.reloadValue(null);
    }
  },

  mounted() {
    if (this.validateImmediately) {
      this.dataValue = {};
      this.$nextTick(() => {
        this.reloadValue();
      });
    }
  },

  methods: {
    reloadValue() {
      let value = this.value;

      if (this.shouldSetDefaultValue()) {
        value = this.getDefaultValue();
        this.$emit("input", value);
      }

      value = this.convertValueType(value, this.inputType);

      if (value !== this.dataValue) {
        this.dataValue = value;
      }
    },

    shouldSetDefaultValue() {
      return this.required && 
             !this.noPreselect && 
             !this.value && 
             this.items?.length;
    },

    getDefaultValue() {
      const firstItem = this.items[0];
      if (typeof firstItem !== "object") {
        return firstItem;
      }
      
      return this.itemValue ? 
        firstItem[this.itemValue] : 
        firstItem.value || firstItem;
    },

    convertValueType(value, type) {
      const converters = {
        string: String,
        int: parseInt,
        float: parseFloat
      };

      return type in converters ? converters[type](value) : value;
    },


    emitInput(value) {
      if (this.required && value === null) {
        return;
      } 

      // eslint-disable-next-line no-undef
      const oldValue = structuredClone(this.value);

      value = this.convertValueType(value, this.outputType);

      this.$emit("input", value);
      this.$emit("modified", value);

      if (this.keepValue) {
        this.handleKeepValue(oldValue);
      }
    },

    handleKeepValue(oldValue) {
      this.$nextTick(() => {
        this.dataValue = null;
        this.$nextTick(() => {
          this.dataValue = oldValue;
        });
      });
    },

    unselectItem(item) {
      const value = this.dataValue.filter(x => x !== item);
      this.emitInput(value);
    }
  }
});
</script>

<style lang="scss">
.select-combobox {
  $root: &;
  display: flex;

   & &__input {
    flex-grow: 1;

    #{$root} & .v-select__selections {
      padding: 10px 0 6px 0;
      gap: 3px;
    }

    #{$root} & .v-chip {
      margin: 0;
    }

    #{$root} & &--success {
      .v-select__slot > input {
        color: var(--v-success-base);
      }
    }

    &.v-text-field--outlined:not(.v-text-field--single-line) {
      &.v-input--dense .v-select__selections {
        padding: 10px 0 6px 0;
        gap: 3px;
      }
    }

    #{$root} & .v-text-field__details {
      margin-bottom: -3px;
    }

    #{$root} & &.v-input--is-disabled {
      .v-input__append-inner {
        visibility: hidden;
      }
    }
  }

  &__chip {
    #{$root} &#{$root}__chip--persistent {
      color: white;
      background-color: var(--v-primary-base) !important;
    }
  }

  &__help {
    margin-top: 2px;
  }
}
</style>
